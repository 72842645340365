import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/hohe-arbeitsbelastung"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727 active-trail">
                      <a
                        href="/fakten-uber-durchfall/"
                        className="active-trail"
                      >
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-ursachen-behand"
                  className="block block-menu"
                >
                  <h2>Durchfall - Ursachen &amp; Behandlung</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/fakten-uber-durchfall/" title>
                          Fakten über Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/durchfallmythen/" title>
                          Tipps bei Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/ursachen-von-durchfall/" title>
                          Ursachen von Durchfall
                        </a>
                      </li>
                      <li className="expanded">
                        <a href="/richtig-essen/" title>
                          Gesunde Essgewohnheiten
                        </a>
                        <ul className="menu">
                          {/*<li className="first leaf">
                            <a href="/nahrungsmittelallergie/" title>
                              Nahrungsmittelallergien
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="/lebensmittelvergiftung/" title>
                              Lebensmittelvergiftung
                            </a>
                          </li>*/}
                          <li className="last leaf">
                            <a href="/rezepte/" title>
                              Leckere Rezepte
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded active-trail">
                        <a
                          href="/stress-durchfall/"
                          title
                          className="active-trail"
                        >
                          Stress und Durchfall
                        </a>
                        <ul className="menu">
                          <li className="first leaf active-trail">
                            <a
                              href="/hohe-arbeitsbelastung/"
                              title
                              className="active-trail active"
                            >
                              Hohe Arbeitsbelastung
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/prufungssituationen/" title>
                              Prüfungssituationen
                            </a>
                          </li>
                        </ul>
                      </li>
                      {/*<li className="leaf">
                        <a href="/chronischer-durchfall/" title>
                          Chronischer Durchfall
                        </a>
                      </li>*/}
                      <li className="leaf">
                        <a href="/gastroenteritis/" title>
                          Magen-Darm-Grippe
                        </a>
                      </li>
                      {/*<li className="leaf">
                        <a href="/norovirus/" title>
                          Norovirus
                        </a>
                      </li>*/}
                      <li className="last leaf">
                        <a href="/durchfall-wahrend-ihrer-periode/" title>
                          Durchfall während Ihrer Periode
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-3" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/imodium-info-box-grafik_0.gif) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4 style={{
                        fontWeight: "bold",
                        fontSize: "30px",
                        lineHeight: "26px",
                        margin: "20px 0 10px 0",
                        fontFamily: "'Open Sans', sans-serif",
                      }}>
                        DIAGNOSE
                        <br />
                        REIZDARM
                      </h4>
                      <p>
                        <img
                          alt
                          src="/sites/default/files/teaser_stress_durchfall.jpg"
                          style={{
                            width: "174px",
                            height: "106px"
                          }}
                        />
                        <br />
                      </p>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "15px 0",
                          "line-height": "18px"
                        }}
                      >
                        Etwa jeder fünfte Erwachsene leidet irgendwann im Leben
                        an Reizdarm-Symptomen. Finden Sie hier Informationen zu
                        Ursachen und Behandlung.
                        <br />
                        <div
                          style={{
                            margin: "10px 0 0 0"
                          }}
                        >
                          <a
                            className="georgia arrow_01"
                            href="/diagnose-reizdarm/"
                          >
                            Mehr erfahren
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <div
                      about="/hohe-arbeitsbelastung"
                      typeof="sioc:Item foaf:Document"
                      className="ds-2col-stacked node node-simple-page view-mode-full clearfix"
                    >
                      <div className="group-header">
                        <h1>Hohe Arbeitsbelastung</h1>
                        <div className="headimg border">
                          <img
                            typeof="foaf:Image"
                            src="/sites/default/files/header_visuals_695x286_arbeitsbelastung.jpg"
                            width="695"
                            height="286"
                          />{" "}
                        </div>
                        <div className="firstrow">
                          <p className="subline">
                            Das Gefühl, von der Arbeit gestresst zu sein, kennt
                            fast jeder. Reagiert Ihr Körper darauf mit
                            Durchfall, zeigt sich, wie sehr Sie körperlich und
                            seelisch angespannt sind. Während der Arbeit unter
                            Durchfall zu leiden, sich schlapp und müde zu fühlen
                            ist nicht nur sehr unangenehm, zudem kann der
                            Durchfall Sie daran hindern, Ihre Aufgaben zu
                            bewältigen.{" "}
                          </p>
                        </div>
                        <div className="field field-name-field-body-left field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                              <h3>So können Sie den Arbeitstag überstehen</h3>
                              <p>
                                Vor allem das Arbeitsleben gestaltet sich heute
                                oft hektisch und belastend. Die Fähigkeit,
                                stressige Momente und Ereignisse erfolgreich
                                bewältigen zu können ist daher essentiell.
                                Obwohl jeder den für sich geeignetsten Weg
                                finden muss, mit dem Stress im Alltag besser
                                umzugehen, gibt es dennoch einige allgemeine
                                Tipps, wie Sie den Stress bei der Arbeit
                                überstehen können:
                              </p>
                              <ul>
                                <li>
                                  <strong>
                                    Gönnen Sie sich kleine Auszeiten
                                  </strong>
                                  <br />
                                  Für die Mittagspause bleibt keine Zeit?
                                  Während einer Pause können Sie neue Kraft
                                  tanken, danach fühlen Sie sich
                                  leistungsfähiger. Verzichten Sie also nicht
                                  völlig auf die kleine Auszeit, sondern
                                  verschieben Sie sie lieber etwas nach hinten.
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <strong>
                                    Achten Sie auf eine{" "}
                                    <a href="/richtig-essen/">
                                      ausgewogene Ernährung
                                    </a>
                                  </strong>
                                  <br />
                                  Wenn Ihr Speiseplan ausreichend Vollkornprodukte und Blattgemüse enthält, kann z.B. 
                                  die Vitamin-B Versorgung sichergestellt werden.
                                  Nahrungsmittel, wie Vollkornsandwiches und
                                  Hafersnacks haben eine entspannende Wirkung –
                                  greifen Sie also zu. Rezepte, die zu einer ausgewogenen Ernährung beitragen
                                  können, finden Sie auf unserer{" "}
                                  <a href="/rezepte/">Rezeptliste</a>.
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <strong>Lernen Sie, Nein zu sagen</strong>
                                  <br />
                                  Es ist wichtig, auf die eigenen Bedürfnisse zu
                                  achten und sich durchzusetzen – jedoch ohne
                                  andere vor den Kopf zu stossen. Versuchen Sie
                                  auch in problematischen Situationen Ruhe zu
                                  bewahren und Konflikte konstruktiv zu lösen.
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <strong>
                                    Bemühen Sie sich um ein effektives
                                    Zeitmanagement
                                  </strong>
                                  <br />
                                  Versuchen Sie Prioritäten zu setzen und Ihren
                                  Tag sinnvoll und realistisch zu planen.
                                  Unangenehme Aufgaben sollten Sie nicht auf die
                                  lange Bank schieben, sondern sofort erledigen.
                                  Sie werden sich erleichtert fühlen, weil Sie
                                  das Schwierigste schon hinter sich haben.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="field field-name-field-body-right field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                              <ul>
                                <li>
                                  <strong>
                                    Üben Sie sich in Gelassenheit, wenn Sie
                                    Dinge nicht ändern können
                                  </strong>
                                  <br />
                                  Versuchen Sie, stressigen Situationen mit
                                  Gelassenheit zu begegnen - wenn Sie diese
                                  nicht ändern können, akzeptieren Sie die Dinge
                                  so, wie sie sind.
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <strong>
                                    Versuchen Sie, Dinge aus einer anderen
                                    Perspektive zu betrachten
                                  </strong>
                                  <br />
                                  Oft ist es hilfreich, störende und belastende
                                  Dinge aus einem anderen Blickwinkel zu
                                  betrachten. Tauschen Sie sich mit anderen
                                  darüber aus, um eine allzu einseitige
                                  Sichtweise zu relativieren.
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <strong>Lachen Sie einfach mal!</strong>
                                  <br />
                                  Nicht alle Situationen sind so ernst, wie es
                                  zunächst den Anschein macht. Wenn Sie lachen,
                                  werden Sie lockerer, bauen Stress ab und
                                  stärken Ihr Immunsystem, das in Stressphasen
                                  häufig geschwächt ist.
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <strong>
                                    Vermeiden Sie übermässigen Genuss von
                                    Alkohol, Nikotin und Koffein
                                  </strong>
                                  <br />
                                  Auch wenn diese Genussmittel kurzfristig
                                  Entspannung versprechen, sind sie keine Lösung
                                  für ein Problem. So kann z.B. zu viel Kaffee
                                  das Stressempfinden zusätzlich verstärken.
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <strong>Bewegen Sie sich ausreichend</strong>
                                  <br />
                                  Sportliche Aktivitäten lösen bestimmte
                                  biochemische Prozesse im Körper aus, die zur
                                  Ausschüttung von Endorphinen (umgangssprachlich "Glückshormone")
                                  führen und Ihnen so beim Stressabbau helfen
                                  können.
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <strong>
                                    Erlernen Sie Entspannungstechniken zur
                                    Stressbewältigung
                                  </strong>
                                  <br />
                                  Verschiedene Entspannungstechniken können
                                  Ihnen helfen, Stress abzubauen, z.B.
                                  Atemübungen, Massagen, Autogenes Training,
                                  Yoga und Thai Chi.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="std-site">
                        <div className="leftcol group-left"></div>
                        <div className="rightcol group-right"></div>
                      </div>
                      <div className="group-footer"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
